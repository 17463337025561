const formatName = require("../../utils/formatName");
const header = require("../header");

module.exports = async (workbook, { party, entries }) => {
  const periods = party.Period.map((p, i) => ({
    ...p,
    name: p.name || `Periodo ${i + 1}`,
  }));

  const sheets = {};

  // const promise = Promise.all(
  periods.map((period) => {

    const sheet = workbook.addWorksheet(formatName(period.name), {
      properties: { tabColor: { argb: "6b69e8" } },
    });

    const columns = [
      { header: "Nome", key: "name", width: 30 },
      { header: "WhatsApp", key: "phone", width: 20 },
      {
        header: "Lote",
        key: "ticketBlock",
        width: 25,
      },
      { header: "Status", key: "status", width: 20 },
    ];

    sheet.columns = columns.flat();
    var headerRow = sheet.getRow(1);
    headerRow.eachCell({ includeEmpty: true }, function (cell) {
      cell.font = { bold: true };
    });

    sheet.addConditionalFormatting({
      ref: `D4:D1000`,
      rules: [
        {
          type: "expression",
          formulae: ["D4 = \"Aprovado\""],
          style: { font: { color: { argb: "FF408243" }, bold: true } }
        },
        {
          type: "expression",
          formulae: ["D4 = \"Recusado\""],
          style: { font: { color: { argb: "FFB23939" }, bold: true } }
        },
        {
          type: "expression",
          formulae: ["D4 = \"Pendente\""],
          style: {
            font: { color: { argb: "FF363636" }, bold: true },
          }
        }
  
      ]
    })
  


    sheets[period.id] = { sheet, period };
  });

  entries.forEach((entry) => {
    entry.TicketEntry.forEach((e) => {
      const sheet = sheets[e.periodId].sheet;
      sheet.addRow({
        name: entry.Owner.name,
        phone: entry.Owner.phone,
        ticketBlock: entry.TicketBlock.name,
        status: e.approved ? "Aprovado" : "Recusado",
      });
    });
  });

  await Promise.all(
    Object.values(sheets).map((sheet) =>
      header(workbook, sheet.sheet, {
        title: `Entradas - ${sheet.period.name}`,
        party,
      })
    )
  );

  return true;
};
