<template>
  <v-card :loading="loading" outlined class="rounded-lg elevation-0">
    <!-- <v-progress-linear
      v-if="!!sold"
      class="rounded-lg rounded-b-0"
      :value="progress"
      height="25"
    >
      {{ filterEntries.length }} de {{ sold }}
    </v-progress-linear> -->
    <v-card-text>
      <div class="d-flex justify-space-between align-center">
        <h6 class="mb-0">Lista de Entrada</h6>
        <!-- <v-btn
          @click="generateExcel"
          color="primary"
          small
          :disabled="!ticketsUsed.length || loading"
          :loading="loadingExcel"
        >
          <v-icon left small>mdi-file-excel</v-icon>
          Exportar
        </v-btn> -->
      </div>

      <v-data-table
        :headers="headers"
        :items="filterEntries"
        :search="search"
        :custom-filter="filterList"
        :custom-sort="customSort"
        order-by="date"
        dense
      >
        <template v-slot:top>
          <div class="d-flex align-center pa-2">
            <v-text-field
              outlined
              dense
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquisar"
              single-line
              hide-details
            />
          </div>
        </template>
        <template v-slot:item.ticketBlock="{ item }">
          {{ item.TicketBlock.TicketGroup.name }} -
          {{ item.TicketBlock.name }}
        </template>
        <template v-slot:item.date="{ item }">
          {{ item.TicketEntry[0].createdAt | date("DD/MM/YY HH:mm") }}
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip
            small
            label
            :color="item.TicketEntry[0].approved ? 'green' : 'red'"
          >
            {{ item.TicketEntry[0].approved ? "Aprovado" : "Reprovado" }}
          </v-chip>
        </template>
        <template v-slot:item.registerBy="{ item }">
          {{ item.TicketEntry[0].RegisterBy?.name }}
          {{ item.TicketEntry[0].Session?.name }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";
import ExcelEntries from "@/utils/exportExcel/entries";

export default {
  data() {
    return {
      loading: false,
      loadingExcel: false,
      refreshDisabled: false,
      search: "",
      entries: [],
      sold: 0,
      selectedPeriod: null,
      headers: [
        { text: "Nome", value: "Owner.name" },
        { text: "Lote", value: "ticketBlock", sortable: false },
        { text: "Status", value: "status" },
        { text: "Horário", value: "date" },
        { text: "Registrado por/em", value: "registerBy" },
      ],
    };
  },
  methods: {
    customSort(items, sortBy, sortDesc, locale) {
      if (!sortBy.length) return items;
      return items.sort((a, b) => {
        if (sortBy[0] == "date")
          return (
            moment(a.TicketEntry[0].createdAt).diff(
              moment(b.TicketEntry[0].createdAt)
            ) * (sortDesc[0] ? -1 : 1)
          );
        if (sortBy[0] == "status")
          return (
            a.TicketEntry[0].approved -
            b.TicketEntry[0].approved * (sortDesc ? -1 : 1)
          );

        if (sortBy[0] == "registerBy") {
          const aa =
            a.TicketEntry[0].RegisterBy?.name || a.TicketEntry[0].Session?.name;
          const bb =
            b.TicketEntry[0].RegisterBy?.name || b.TicketEntry[0].Session?.name;
          return aa.localeCompare(bb, locale) * (sortDesc ? -1 : 1);
        }
        if (sortBy[0] == "Owner.name") {
          const aa = a.Owner.name;
          const bb = b.Owner.name;
          return aa.localeCompare(bb, locale) * (sortDesc ? -1 : 1);
        }

        return a[sortBy[0]].localeCompare(b[sortBy[0]]) * (sortDesc ? -1 : 1);
      });
    },
    filterName(search, member) {
      if (!member.Owner.name) return false;
      return member.Owner.name.toLowerCase().includes(search.toLowerCase());
    },
    filterList(value, search, item) {
      return this.filterName(search, item);
    },
    async generateExcel() {
      try {
        this.loadingExcel = true;
        await ExcelEntries(
          structuredClone({ entries: this.entries, party: this.party })
        );
      } catch (e) {
        console.log(e);
      } finally {
        this.loadingExcel = false;
      }
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    progress() {
      return (this.filterEntries.length / this.sold) * 100;
    },

    filterEntries() {
      return this.selectedPeriod
        ? this.ticketsUsed
            .filter((e) =>
              e.TicketEntry.some((t) => t.periodId == this.selectedPeriod)
            )
            .map((e) => {
              return {
                ...e,
                TicketEntry: e.TicketEntry.filter(
                  (t) => t.periodId == this.selectedPeriod
                ),
              };
            })
        : this.ticketsUsed;
    },
  },
  props: {
    partyId: {
      type: String,
      required: true,
    },
    party: {
      type: Object,
      required: true,
    },
    tickets: {
      type: Array | Boolean,
      default: () => [],
    },
    ticketsUsed: {
      type: Array,
      required: true,
    },
  },
};
</script>
