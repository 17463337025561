<template>
  <v-dialog v-model="dialog" persistent width="320px">
    <v-card v-if="!!session">
      <v-card-title>
        <div class="d-flex align-center">
          <h6 class="mb-0 lh-1">Sessão de entrada</h6>
          <v-chip label v-if="session.name" x-small class="ml-2">
            {{ session.name }}
          </v-chip>
        </div>
      </v-card-title>
      <v-card-text class="pb-0 d-flex flex-column justify-center">
        <div class="d-flex justify-center">
          <qrcode-vue
            class="d-flex justify-center pa-3 ma-1 white rounded"
            :value="qrCodeLink"
            size="260"
            level="M"
          />
        </div>
        <!-- {{ qrCodeLink }} -->
        <v-text-field
          v-model="scannerName"
          label="Nome do scanner"
          outlined
          dense
          class="mt-4"
          hint="Caso deixe em branco, o nome será solicitado no scanner"
        />
      </v-card-text>
      <v-card-actions class="justify-center pb-2">
        <v-btn text @click="close(false)"> Fechar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import KJUR from "jsrsasign";
import QrcodeVue from "qrcode.vue";
export default {
  components: { QrcodeVue },
  data: () => ({
    dialog: false,
    session: null,
    scannerName: "",
  }),
  methods: {
    open(data) {
      this.dialog = true;
      this.session = data;
    },
    close() {
      this.dialog = false;
    },
  },
  computed: {
    qrCodeLink() {
      const header = { alg: "HS256", typ: "JWT" };
      const payload = {
        id: this.session.id,
        name: this.scannerName || undefined,
      };

      var sHeader = JSON.stringify(header);
      var sPayload = JSON.stringify(payload);
      var token = KJUR.jws.JWS.sign(
        "HS256",
        sHeader,
        sPayload,
        this.session.secret
      );

      const url = new URL(window.location.href);
      url.pathname = `/staff/scanner/${btoa(token)}`;
      return url.href;
    },
  },
  mounted() {
    this.$root.$on("entrance-session-qr", this.open);
  },
  props: {
    party: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style></style>