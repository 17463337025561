<template>
  <v-dialog
    v-model="dialog"
    @click:outside="close()"
    scrollable
    :persistent="loading"
    max-width="400px"
  >
    <v-card :loading="loading">
      <v-card-text class="pt-6 pb-0" style="overflow: hidden">
        <h5 class="text-center mb-4">Buscar ingresso por CPF</h5>
        <v-form v-model="valid" @submit.prevent="findByCpf">
          <v-text-field-simplemask
            v-model="cpf"
            label="CPF"
            v-bind:properties="{
              autofocus: true,
              prefix: '',
              suffix: '',
              outlined: true,
              placeholder: '999.999.999-99',
              type: 'tel',
              rules: cpfRules,
              disabled: loading,
            }"
            v-bind:options="{
              inputMask: '###.###.###-##',
              outputMask: '###.###.###-##',
              empty: '',
              applyAfter: false,
              alphanumeric: false,
              lowerCase: false,
            }"
          />
          <v-alert v-if="error" type="error" dense>
            {{ error }}
          </v-alert>
        </v-form>
      </v-card-text>

      <v-card-actions class="pb-4">
        <v-btn :disabled="loading" @click="close()" text> Cancelar </v-btn>
        <v-spacer />
        <v-btn :disabled="loading || !valid" @click="findByCpf" color="primary">
          Buscar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PARTY from "@/services/admin/party";
import validateCpf from "@/utils/validate-cpf";

import { mapGetters } from "vuex";
export default {
  data: () => ({
    dialog: false,
    loading: false,
    error: null,
    valid: false,
    cpf: null,
    cpfRules: [
      (v) => !!v || "CPF é obrigatório",
      (v) => validateCpf(v) || "CPF inválido",
    ],
  }),
  methods: {
    // keydownHandler() {
    //   if (this.valid) {
    //     this.findByCpf();
    //   }
    // },
    async findByCpf() {
      try {
        this.loading = true;
        this.error = null;
        const { ticket } = await PARTY.entrance.ticket.findByCpf(
          this.party.organizationId,
          this.party.id,
          this.cpf
        );
        this.$emit("entry-confirm", ticket.code);
        this.close(true);
      } catch (error) {
        this.error = error.message || error;
      } finally {
        this.loading = false;
      }
    },
    open() {
      this.loading = false;
      this.error = null;
      this.cpf = null;
      this.dialog = true;
      this.processCode(code);
    },
    close(force = false) {
      if (!force && this.loading) return;
      this.dialog = false;
      this.cpf = null;
    },
  },
  watch: {
    dialog(val) {
      if (!val) this.$emit("close");
    },
    valid(val) {
      if (val && this.cpf && this.cpf.length > 13) this.findByCpf();
    },
    cpf(val) {
      this.error = null;
    },
  },

  computed: {
    ...mapGetters("organization", ["selectedOrganization", "partyById"]),
    party() {
      return this.partyById(this.$route.params.partyId);
    },
  },
  mounted() {
    this.$parent.$on("find-by-cpf", this.open);
  },
};
</script>

<style></style>
