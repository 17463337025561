<template>
  <div style="height: 100vh; height: 100dvh; position: relative">
    <!-- Centralizer -->
    <div
      class="d-flex flex-column align-center justify-center"
      style="height: 100vh; height: 100dvh"
    >
      <v-progress-circular
        indeterminate
        color="white"
        size="40"
        v-if="loading"
      />
      <div v-if="error">
        <v-alert type="error" :value="true"> {{ error }} </v-alert>
        <v-btn block @click="destroy">Tentar novamente</v-btn>
      </div>
    </div>

    <!-- SCANNER -->
    <qrcode-stream
      v-if="!destroyed && !error"
      @decode="decode"
      :track="paintOutline"
      @init="onInit"
      :camera="disableCamera ? 'off' : camera"
      :torch="torch"
      :style="{
        position: 'absolute',
        top: '0px',
      }"
    />

    <v-card
      v-if="party"
      class="d-flex gap-3 rounded-lg align-center"
      style="position: absolute; inset: auto 15px 15px; bottom: 15px"
    >
      <div>
        <v-img
          :src="party?.cover"
          :aspect-ratio="16 / 9"
          :width="(74 * 16) / 9"
          height="74px"
          class="rounded-r-0 rounded-l-lg"
        />
      </div>
      <div class="flex-grow-1 flex-shrink-1 py-1">
        <div style="display: grid">
          <h6 class="mb-0 text-truncate">
            {{ party?.name }}
          </h6>
          <div class="d-flex align-center flex-wrap gap-x-2">
            <span v-if="!currentPeriod">
              {{ party | startEndDate("date", "endDate") }}
            </span>
            <template v-if="currentPeriod">
              <span
                class="text-overline font-weight-medium"
                style="line-height: 1rem"
              >
                {{ currentPeriod.name }}
              </span>
              <span>•</span>
              <span class="flex-grow-0 flex-shrink-1">
                {{ currentPeriod | startEndDate("startDate", "endDate") }}
              </span>
            </template>
          </div>
        </div>
      </div>
      <div class="flex-shrink-0 pr-4">
        <v-btn @click="findByCpf" color="primary" small fab elevation="0">
          <v-icon>mdi-account-search</v-icon>
        </v-btn>
      </div>
    </v-card>

    <div
      style="position: absolute; top: 0; left: 0; right: 0"
      class="d-flex align-center gap-2 ma-4"
    >
      <v-btn fab @click="previewPage">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-spacer />
      <v-btn
        v-if="!error"
        :disabled="loading || !torchSupport"
        fab
        @click="torch = !torch"
        color="success"
        small
      >
        <v-icon>mdi-flashlight{{ torch ? "-off" : "" }}</v-icon>
      </v-btn>
      <v-btn
        v-if="!error"
        @click="toggleCamera"
        :disabled="loading"
        fab
        color="info"
      >
        <v-icon>mdi-camera-switch</v-icon>
      </v-btn>
    </div>

    <renew-token />
    <FindByCPF @entry-confirm="decode" />
    <entry-confirm-modal @close="disableCamera = false" :party="party" />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { QrcodeStream } from "vue-qrcode-reader";
import EntryConfirmModal from "../../../components/entrance/EntryConfirmModal.vue";
import FindByCPF from "../../../components/entrance/FindByCPF.vue";

export default {
  metaInfo() {
    return { title: `📷 Scanner` };
  },
  data() {
    return {
      loading: false,
      error: false,
      destroyed: false,
      torch: false,
      torchSupport: false,
      camera: "rear",
      disableCamera: false,
    };
  },
  methods: {
    ...mapActions("organization", {
      updatePartyById: "partyById",
    }),
    findByCpf() {
      this.$emit("find-by-cpf");
    },
    decode(content) {
      this.disableCamera = true;
      this.$emit("entry-confirm", content);
    },
    toggleCamera() {
      this.camera = this.camera === "rear" ? "front" : "rear";
    },
    destroy() {
      this.error = false;
      this.destroyed = true;
      this.$nextTick(() => {
        this.destroyed = false;
      });
    },
    async onInit(promise) {
      this.loading = true;
      this.error = false;

      try {
        const { capabilities } = await promise;

        this.torchSupport = !!capabilities.torch;
      } catch (error) {
        if (error.name == "OverconstrainedError") {
          this.toggleCamera();
        } else {
          this.error = "Não foi possível acessar a câmera";
        }
      } finally {
        this.loading = false;
      }
    },
    previewPage() {
      try {
        if(history.length > 1) {
          this.$router.back();
        } else {
          this.$router.push({
            name: "admin.entrance",
          });
        }
      } catch (e) {
        this.$router.push({
          name: "admin.entrance",
        });
      }
    },
    paintOutline(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const [firstPoint, ...otherPoints] = detectedCode.cornerPoints;

        ctx.strokeStyle = "red";

        ctx.beginPath();
        ctx.moveTo(firstPoint.x, firstPoint.y);
        for (const { x, y } of otherPoints) {
          ctx.lineTo(x, y);
        }
        ctx.lineTo(firstPoint.x, firstPoint.y);
        ctx.closePath();
        ctx.stroke();
      }
    },
  },

  computed: {
    ...mapGetters("organization", ["selectedOrganization", "partyById"]),
    party() {
      return this.partyById(this.$route.params.partyId);
    },
    currentPeriod() {
      var hasPeriods = this.party?.Period && this.party.Period.length > 0;
      if (!hasPeriods) return null;
      var currentPeriod = this.party.Period.sort((a, b) => {
        return new Date(a.endDate) - new Date(b.endDate);
      }).find((period) => {
        return new Date(period.endDate) > new Date();
      });

      return {
        ...currentPeriod,
        inner:
          !!currentPeriod && new Date(currentPeriod.startDate) < new Date(),
      };
    },
  },
  components: {
    QrcodeStream,
    EntryConfirmModal,
    FindByCPF,
    RenewToken: () => import("@/components/global/RenewToken"),
  },
  mounted() {
    try {
      this.updatePartyById(this.$route.params.partyId);
    } catch (e) {
      console.log(e);
    }
  },
  watch: {
    "selectedOrganization.id"() {
      this.$router.push({
        name: "admin.entrance",
      });
    },
  },
};
</script>
