const ExcelJS = require("exceljs");
const downloadFile = require("./utils/downloadFile");
const periodsTable = require("./modules/entries/periodsTable");
const header = require("./modules/header");
const formatName = require("./utils/formatName");

module.exports = async ({ party, entries }) => {
  entries = entries.sort((a, b) => a.Owner.name.localeCompare(b.Owner.name));
  const workbook = new ExcelJS.Workbook();
  workbook.creator = "TicketMe";
  workbook.lastModifiedBy = "TicketMe";

  workbook.title = `Entradas - ${formatName(party.name)}`;
  workbook.created = new Date();

  const sheetResume = workbook.addWorksheet("Resumo", {
    properties: { tabColor: { argb: "242939" } },
  });
  const hasPeriods = party.Period && !!party.Period.length;
  const statusColumns = hasPeriods
    ? [{
      header: "Presença",
      key: "presence",
      width: 10,
      style: { alignment: { horizontal: "center" } },
    }, ...party.Period.map((p, i) => {
      return {
        header: p.name || `Periodo ${i + 1}`,
        key: `period-${p.id}`,
        width: 30,
        style: { alignment: { horizontal: "center" } },
      };
    })]
    : [{ header: "Status", key: "status", width: 20 }];
  const columns = [
    { header: "Nome", key: "name", width: 30 },
    { header: "WhatsApp", key: "phone", width: 20 },
    {
      header: "Lote",
      key: "ticketBlock",
      width: 20,
    },
    ...statusColumns,
  ];
  sheetResume.columns = columns.flat();
  var headerRow = sheetResume.getRow(1);
  headerRow.eachCell({ includeEmpty: true }, function (cell) {
    cell.font = { bold: true };
  });

  await header(workbook, sheetResume, { title: "Entradas - Resumo", party });

  const lastColumn = String.fromCharCode(65 + columns.length - 1);
  // ROWS
  entries.forEach((entry) => {
    var status;
    if (hasPeriods) {
      const defaultStatus = party.Period.reduce((acc, p) => {
        acc[`period-${p.id}`] = "Pendente";
        return acc;
      }, {});

      status = Object.assign(
        defaultStatus,
        entry.TicketEntry.reduce((acc, e) => {
          acc[`period-${e.periodId}`] = e.approved
            ? "Aprovado"
            : acc[`period-${e.periodId}`] || "Recusado";
          return acc;
        }, {})
      );
    } else
      status = {
        status: entry.TicketEntry[0].approved ? "Aprovado" : "Recusado",
      };

    var r = sheetResume.addRow({
      name: entry.Owner.name,
      phone: {
        hyperlink: `http://wa.me/${entry.Owner.ddi}${entry.Owner.phone.replace(
          /[^0-9]/g,
          ""
        )}?text=Olá, ${entry.Owner.name}!`,
        text: entry.Owner.phone,
      },
      ticketBlock: `${entry.TicketBlock.TicketGroup.name} - ${entry.TicketBlock.name}`,
      ...status,
    });

    if (hasPeriods) {
      // Add percentage formula
      var cell = r.getCell(4);
      cell.value = {
        formula: `COUNTIF(E${r.number}:${lastColumn + r.number
          },"Aprovado")/COUNTA(E${r.number}:${lastColumn + r.number
          })`,
        date1904: false,
      };

      // set number format to percentage
      cell.numFmt = "0.00%";


    }

  });

  sheetResume.addConditionalFormatting({
    ref: `D4:${String.fromCharCode(65 + columns.length - 1)}${entries.length + 3}`,
    rules: [
      {
        type: "expression",
        formulae: ["D4 = \"Aprovado\""],
        style: { font: { color: { argb: "FF408243" }, bold: true } }
      },
      {
        type: "expression",
        formulae: ["D4 = \"Recusado\""],
        style: { font: { color: { argb: "FFB23939" }, bold: true } }
      },
      {
        type: "expression",
        formulae: ["D4 = \"Pendente\""],
        style: {
          font: { color: { argb: "FF363636" }, bold: true },
        }
      }

    ]
  })

  if (hasPeriods) periodsTable(workbook, { party, entries });

  downloadFile(workbook, `Entradas - ${party.name}`);

  return true;
};
