<template>
  <v-row class="mx-0" align-content="stretch" dense align="stretch">
    <v-col cols="12" md="7">
      <v-card outlined :loading="loading" rounded="lg">
        <v-card-title class="justify-space-between">
          <h6 class="mb-0">Validações por setor</h6>
        </v-card-title>
        <v-card-text>
          <vue-perfect-scrollbar
            :settings="{ suppressScrollX: false, wheelPropagation: false }"
            class="ps scroll rounded-0"
            style="max-height: 280px; height: 300px; width: 100%"
          >
            <v-data-table
              :items="ticketGroups"
              :headers="headers"
              hide-default-footer
              single-expand
              item-key="id"
              show-expand
              :expanded.sync="expanded"
            >
              <template v-slot:item.entries="{ item }">
                <div class="d-flex gap-1 align-center">
                  <v-progress-circular
                    color="primary"
                    size="18"
                    width="5"
                    :value="(item.entries / item.count) * 100"
                  />
                  <span class="ml-1 font-weight-bold">
                    {{ item.entries }}
                  </span>
                  de
                  <span class="font-weight-bold">
                    {{ item.count }}
                  </span>
                  ingresso{{ item.count == 1 ? "" : "s" }}
                </div>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="pa-2">
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Lote</th>
                          <th class="text-right">Qtde</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="ticket in item.TicketBlock" :key="ticket.id">
                          <td>
                            <b class="d-block">{{ ticket.name }}</b>
                          </td>
                          <td class="text-right">
                            <div class="d-flex align-center gap-1 justify-end">
                              <v-progress-circular
                                color="primary"
                                size="16"
                                width="4"
                                :value="(ticket.entries / ticket.count) * 100"
                              />
                              <span class="font-weight-bold">
                                {{ ticket.entries }}
                              </span>
                              <span> de </span>
                              <span class="font-weight-bold">
                                {{ ticket.count }}
                              </span>
                              <span>
                                ingresso{{ ticket.count == 1 ? "" : "s" }}</span
                              >
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </td>
              </template>
            </v-data-table>
          </vue-perfect-scrollbar>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" md="5">
      <v-card outlined :loading="loading" rounded="lg">
        <v-card-text>
          <ApexCharts
            type="donut"
            height="360px"
            :options="chartOptions"
            :series="chartSeries"
          />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import ApexCharts from "vue-apexcharts";

export default {
  name: "EntriesByGroup",
  components: { ApexCharts },
  data: () => ({
    headers: [
      { text: "Setor", value: "name" },
      { text: "Validados", value: "entries" },
      { text: "", value: "data-table-expand" },
    ],
    expanded: [],
  }),
  props: {
    entries: Array,
    tickets: {
      type: Array | Boolean,
      default: () => [],
    },
    ticketsUsed: Array,
    party: Object,
    loading: Boolean,
  },
  computed: {
    ticketGroups() {
      if (!this.tickets) return [];

      const ticketGroups = this.tickets.reduce((acc, ticket) => {
        const ticketBlock = ticket.TicketBlock;
        const ticketGroup = ticketBlock.TicketGroup;

        if (!acc[ticketGroup.id])
          acc[ticketGroup.id] = {
            id: ticketGroup.id,
            name: ticketGroup.name,
            count: 0,
            entries: 0,
            ticketBlock: {
              [ticketBlock.id]: {
                id: ticketBlock.id,
                name: ticketBlock.name,
                count: 0,
                entries: 0,
              },
            },
          };

        if (!acc[ticketGroup.id].ticketBlock[ticketBlock.id]) {
          acc[ticketGroup.id].ticketBlock[ticketBlock.id] = {
            id: ticketBlock.id,
            name: ticketBlock.name,
            count: 0,
            entries: 0,
          };
        }

        const hasEntry = ticket.TicketEntry.some(({ approved }) => approved);
        acc[ticketGroup.id].count += 1;
        acc[ticketGroup.id].entries += hasEntry ? 1 : 0;
        acc[ticketGroup.id].ticketBlock[ticketBlock.id].count += 1;
        acc[ticketGroup.id].ticketBlock[ticketBlock.id].entries += hasEntry
          ? 1
          : 0;

        return acc;
      }, {});

      return Object.values(ticketGroups).map((group) => {
        group.TicketBlock = Object.values(group.ticketBlock);
        return group;
      });
    },

    chartSeries() {
      return this.ticketGroups.map((group) => group.count);
    },
    chartOptions() {
      let categories = this.xaxis;
      return {
        chart: {
          type: "donut",

          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: "14px",
                  fontWeight: 400,
                  offsetY: -10,
                },
                value: {
                  show: true,
                  fontSize: "22px",
                  fontWeight: 600,
                  offsetY: 10,
                },
              },
            },
          },
        },
        labels: this.ticketGroups.map((group) => group.name),
        legend: {
          position: "bottom",
          horizontalAlign: "center",
        },
        tooltip: {
          // x: { show: false },
          y: {
            formatter: (seriesName) =>
              seriesName.toFixed(0) + " ingressos válidos",
          },
        },
      };
    },
  },
};
</script>

<style>
</style>