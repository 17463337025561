var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column gap-2"},[_c('div',[_c('v-card',{staticClass:"d-flex align-center gap-x-4 flex-wrap rounded-lg pa-2",attrs:{"elevation":"0"}},[_c('div',{staticClass:"d-flex gap-3 align-center flex-grow-1"},[_c('div',[(!_vm.party)?_c('v-skeleton-loader',{staticClass:"rounded-lg",attrs:{"height":"60px","width":`${60 * (16 / 9)}px`,"type":"image"}}):_c('v-img',{staticClass:"rounded-lg",attrs:{"aspect-ratio":16 / 9,"src":_vm.party?.cover,"height":"60px","width":`${60 * (16 / 9)}px`}})],1),_c('div',[(!_vm.party)?_c('v-skeleton-loader',{staticClass:"rounded-lg mb-1",attrs:{"width":"100px","height":"12px","type":"card"}}):_c('p',{staticClass:"mb-0 text-overline lh-1"},[_vm._v(" "+_vm._s(_vm._f("date")(_vm.party?.date,"DD/MM/YY - HH:mm"))+" ")]),(!_vm.party)?_c('v-skeleton-loader',{staticClass:"rounded-lg",attrs:{"width":"180px","height":"24px","type":"card"}}):_c('h6',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.party?.name))])],1)]),_c('div',{staticClass:"d-flex flex-wrap flex-grow-1 flex-shrink-1 gap-1"},[_c('v-btn',{staticClass:"flex-grow-1",attrs:{"color":"primary","text":"","to":{
            name: 'admin.entrance.sessions',
            params: { partyId: _vm.partyId },
          }}},[_vm._v(" Sessões ")]),_c('v-btn',{staticClass:"flex-grow-1",attrs:{"color":"primary","text":"","to":`/admin/organization/parties/${_vm.partyId}/management#reports`}},[_vm._v(" Relatórios ")]),_c('v-btn',{staticClass:"flex-grow-1",attrs:{"color":"primary","text":"","to":{
            name: 'admin.entrance.scan',
            params: { partyId: _vm.partyId },
          }}},[_vm._v(" Scanner ")])],1)]),_c('div',{staticClass:"d-flex flex-wrap justify-space-between align-center mx-1"},[(!!_vm.periods && _vm.periods.length)?_c('v-chip-group',{attrs:{"center-active":"","mandatory":"","show-arrows":""},model:{value:(_vm.selectedPeriod),callback:function ($$v) {_vm.selectedPeriod=$$v},expression:"selectedPeriod"}},[_c('v-chip',{staticClass:"my-0",attrs:{"value":null,"disabled":_vm.loading,"filter":"","small":"","color":"primary"}},[_vm._v(" Todos ")]),_vm._l((_vm.periods),function(period){return _c('v-chip',{key:period.id,staticClass:"my-0",attrs:{"value":period.id,"disabled":_vm.loading,"filter":"","small":"","color":"primary"}},[_vm._v(" "+_vm._s(period.name)+" ")])})],2):_vm._e(),_c('div',[_c('v-btn',{attrs:{"left":"","icon":"","small":"","loading":_vm.loading,"disabled":_vm.refreshDisabled},on:{"click":_vm.getEntrance}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-refresh")])],1),_c('small',{},[_vm._v(" Última atualização: "+_vm._s(_vm._f("date")(_vm.lastUpdate,"HH:mm:ss"))+" ")])],1)],1)],1),(_vm.party)?_c('EntriesSummary',{attrs:{"party":_vm.party,"loading":_vm.loading,"entries":_vm.entries,"duration":_vm.duration,"tickets":_vm.filteredTickets,"ticketsUsed":_vm.ticketsUsed,"selectedPeriod":_vm.selectedPeriodObj}}):_vm._e(),(_vm.party)?_c('EntriesEvolution',{staticClass:"mx-1",attrs:{"party":_vm.party,"loading":_vm.loading,"entries":_vm.entries,"duration":_vm.duration,"tickets":_vm.filteredTickets,"ticketsUsed":_vm.ticketsUsed}}):_vm._e(),(_vm.party)?_c('EntriesByGroup',{attrs:{"party":_vm.party,"loading":_vm.loading,"entries":_vm.entries,"tickets":_vm.filteredTickets,"ticketsUsed":_vm.ticketsUsed}}):_vm._e(),(_vm.party)?_c('entries-table',{staticClass:"mx-1",attrs:{"party":_vm.party,"partyId":_vm.partyId,"tickets":_vm.filteredTickets,"ticketsUsed":_vm.ticketsUsed}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }